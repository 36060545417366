import React from 'react';
import {
  getApiNotificationSetting,
  geApiNotificationUserSetting,
  postApiNotificationUserSetting,
  postApiAotificationAubscribeWX,
  getWeixinPushWXlinkPush,
  postApiNotificationSubscribeWXTicket,
  getApiNotificationSubscribeInfo,
  postApiNotificationSubscribe,
} from 'components/api';
import { getProductId } from 'components/util';
import { getEnv, ALL_ENV } from 'components/env';
import WXJSSDK from 'components/wxjssdk';
import WxSubscribe from './components/wx-subscribe';
import EmailSubscribe from './components/email-subscribe';
import { SCENE, METHOD, TYPE, QQ_REDIRECT_URL } from './constants';
import NoSubscribe from './components/no-subscribe';
/**
 * 移动端通知逻辑
 * 1. 判断当前环境
 * 2. 获取产品配置，确定当前环境用什么消息通知
 * 3. 获取用户配置，确定该通知是否可用
 * 4. 使用该通知
 */
export default class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.subscribePost = this.subscribePost.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.productId = getProductId();
    this.env = getEnv();
    // 企业微信等环境都归为第三方环境
    if ([
      ALL_ENV.wxwork,
      ALL_ENV.miniprogramPlugin,
    ].includes(this.env)) {
      this.env = ALL_ENV.normal;
    }

    this.wxjssdk = this.getWxSdk();
    this.state = {
      channel: '',
      wxSdkReady: false,
      wxTemplateId: '',
    };
  }

  componentDidMount() {
    if (process.env.TARO_ENV === 'weapp' || !this.wxjssdk.wxjssdkReady) {
      // 小程序不需要的逻辑放到后面执行
      return;
    }
    // 保证在wxsdk加载完毕后再渲染微信消息通知
    this.wxjssdk.wxjssdkReady.then(() => {
      this.setState({ wxSdkReady: true });
    });
  }

  getWxSdk() {
    return new WXJSSDK();
  }

  // 获取微信消息通知模板ID
  loadWxTemplateId(scene) {
    // 目前只有微信中需要获取模板ID 拉起微信订阅通知
    if (this.env !== ALL_ENV.wechat && this.env !== ALL_ENV.wx_mini) {
      return;
    }
    getApiNotificationSubscribeInfo(this.productId, {
      channel: METHOD.wx_subscribe,
      scene,
    })
      .then((res) => {
        if (
          res.status === 0
                    && res.data
                    && res.data.template_ids
                    && Array.isArray(res.data.template_ids)
        ) {
          this.setState({
            wxTemplateId: res.data.template_ids.join(','),
          });
        }
      });
  }

  // 手动订阅发帖
  subscribePost(postId) {
    this.type = TYPE.POST;
    this.subscribe(SCENE.POST, postId);
  }

  // 手动订阅回复
  subscribeReply(replyId) {
    this.type = TYPE.REPLY;
    this.subscribe(SCENE.REPLY, replyId);
  }

  // 发起订阅确认
  // NOTE 注意小程序 嵌入webview这种形式是不弹窗, 旧逻辑
  subscribe(scene, id) {
    this.id = id;
    this.scene = scene;
    this.loadWxTemplateId(scene);
    if (this.env === ALL_ENV.miniprogramWeb) {
      return;
    }
    this.getSetting()
      .then((channel) => {
        this.setState({
          channel,
        });
      });
  }

  /** 获取配置并返回能用的场景 */
  getSetting() {
    return getApiNotificationSetting({ productId: this.productId })
      .then((setting) => {
        if (setting.status !== 0) {
          return Promise.reject(setting.message);
        }
        const { method, scene } = setting.data;
        const channel = method[this.env];
        if (!channel || !scene.includes(this.scene)) {
          const err = '当前通知渠道已关闭';
          return Promise.reject(err);
        }
        return geApiNotificationUserSetting({ productId: this.productId, channel });
      })
      .then((res) => {
        if (res.status !== 0 || !res.data.status) {
          const err = '用户取消当前通知通道';
          return Promise.reject(err);
        }
        return res.data.channel;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  /**
     * 用户确认流程
     * 1. 一次性订阅  => 获取ticket 和 link => 将ticket 和 id 绑定。跳转link
     * 2. 消息通知 => 走微信流程。用户订阅后告诉后端 用户订阅了这个帖子 / 评论
     * 3. qq里跳固定地址
     */
  onConfirm() {
    const { channel } = this.state;

    if (channel === METHOD.qq_redirect) {
      this.setState({ channel: '' });
    }
    // 微信一次性订阅走跳转地址
    if (
      channel === METHOD.wx_subscribe_one_time
            || channel === METHOD.wx_subscribe_one_time_txc
    ) {
      this.handleWxSubscribeOneTime();
    }

    // 如果是微信消息通知，需要上报接口告诉后端，用户订阅了哪个
    if (channel === METHOD.wx_subscribe) {
      this.handleWxSubscribe();
    }

    if (channel === METHOD.wx_mini_subscribe) {
      this.handleWxMiniConfirm();
    }

    if (channel === METHOD.email) {
      this.handlEmailConfirm();
    }
  }

  // 处理 微信订阅通知 确认
  handleWxSubscribe() {
    postApiAotificationAubscribeWX({
      productId: this.productId,
      scene: this.scene,
      type: this.type,
      id: this.id,
    })
      .then(() => {
        this.setState({ channel: '' });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ channel: '' });
      });
  }

  // 处理 微信一次性订阅 确认
  handleWxSubscribeOneTime() {
    getWeixinPushWXlinkPush({ productId: this.productId })
      .then((linkData) => {
        if (linkData.message) {
          return Promise.resolve({
            error: true,
            linkData,
            message: '获取 link 数据异常',
          });
        }

        const {
          ticket,
          openlink,
        } = linkData.data;
        return { ticket, openlink };
      })
      .then(({ ticket, openlink }) => postApiNotificationSubscribeWXTicket({
        productId: this.productId,
        scene: this.scene,
        type: this.type,
        id: this.id,
        ticket,
      })
        .then(() => {
          this.setState({ channel: '' });
          window.location.href = openlink;
        }))
      .catch(() => {
        this.setState({ channel: '' });
      });
  }

  // 这个逻辑用到小程序api, 在index.weapp.jsx中处理
  handleWxMiniConfirm() {}

  // 邮件的确认逻辑
  handlEmailConfirm() {
    postApiNotificationSubscribe({
      productId: this.productId,
      scene: this.scene,
      type: this.type,
      id: this.id,
      channel: this.state.channel,
    })
      .then(() => {
        this.setState({ channel: '' });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ channel: '' });
      });
  }

  onCancel(val) {
    const { channel } = this.state;
    if (val === 'cancel') {
      this.setState({ channel: '' });
    } else {
      postApiNotificationUserSetting({
        productId: this.productId,
        channel,
        status: false,
      });
      this.setState({ channel: '' });
    }
  }

  render() {
    const { channel, wxSdkReady, wxTemplateId } = this.state;
    if (!channel) {
      return null;
    }
    if (channel === '当前通知渠道已关闭') {
      return (
        <NoSubscribe
          onClose={() => this.setState({ channel: '' })}
        />
      );
    }
    const isOneTime = [
      METHOD.qq_redirect,
      METHOD.wx_subscribe_one_time,
      METHOD.wx_subscribe_one_time_txc,
      METHOD.wx_mini_subscribe,
    ].includes(channel);

    const isEmail = [
      METHOD.email,
    ].includes(channel);

    if (isOneTime) {
      return (
        <WxSubscribe
          onConfirm={this.onConfirm}
          onCancel={this.onCancel}
          isMQQ={channel === METHOD.qq_redirect}
          isWxMini={channel === METHOD.wx_mini_subscribe}
        />
      );
    }

    if (isEmail) {
      return (
        <EmailSubscribe
          scene={this.scene}
          onConfirm={this.onConfirm}
          onCancel={this.onCancel}
        />
      );
    }

    // 微信消息通知 在wx sdk加载完和模板ID加载完后在渲染
    if (wxSdkReady && wxTemplateId && channel === METHOD.wx_subscribe) {
      return (
        <WxSubscribe
          onConfirm={this.onConfirm}
          onCancel={this.onCancel}
          isMQQ={false}
          isWxSubscribe={true}
          wxTemplateId={wxTemplateId}
        />
      );
    }
    return null;
  }
}
