/*
    deprecated-mobile-embed\src\service\saga\saga-util\post
*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tag from 'components/tag';
import { ariaRole } from 'components/util/aria';
import successImg from './img/send-ok-wx-push.png';
import { Modal } from 'components/modal';
import WxOpenSubscribe from 'components/wxjssdk/wx-open-subscribe';
import './index.less';

class WxSubscribe extends Component {
  render() {
    const { isMQQ, onCancel, onConfirm, isWxMini, isWxSubscribe = false, wxTemplateId } = this.props;
    const touchEvent = {};
    if (isWxMini) {
      touchEvent.onTap = onConfirm;
    } else {
      touchEvent.onClick = onConfirm;
    }
    return (
      <Modal onClose={onCancel}>
        <Tag.div className={classnames('wx-subscribe', 'wx-subscribe--show')}>
          <Tag.header className="wx-subscribe__header">成功提交</Tag.header>
          <Tag.img className="wx-subscribe__success-icon" src={successImg} alt="" />
          <Tag.article className="wx-subscribe__article">
            {isMQQ ? (
              <Tag.span>感谢您的建议和反馈</Tag.span>
            ) : (
              <Tag.span>马上开通提醒, 有新回复第一时间通知你</Tag.span>
            )}
          </Tag.article>
          <Tag.footer className="wx-subscribe__footer">
            <Tag.span
              onClick={onCancel}
              {...ariaRole('button')}
              tabIndex={-1}
              className="wx-subscribe__btn wx-subscribe__btn--cancel"
            >取消</Tag.span>
            {isWxSubscribe && wxTemplateId && (
              <WxOpenSubscribe
                onCancel={onCancel}
                onConfirm={onConfirm}
                className="wx-subscribe__subscribe"
                wxTemplateId={wxTemplateId}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '50%',
                  borderLeft: '1px #d2d3d5 solid',
                  fontSize: '0.36rem',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    color: '#298afc',
                    textAlign: 'center',
                  }}
                >开通微信提醒</div>
              </WxOpenSubscribe>
            )}
            {!isWxSubscribe && (
              <Tag.span
                className="wx-subscribe__btn wx-subscribe__btn--confirm"
                {...ariaRole('button')}
                tabIndex={0}
                {...touchEvent}
              >
                {isMQQ ? '确认' : '开通微信提醒'}
              </Tag.span>
            )}
          </Tag.footer>
        </Tag.div>
      </Modal>
    );
  }
}
WxSubscribe.defaultProps = {
  onCancel: () => {
    console.log('onCancel方法未绑定');
  },
  onConfirm: () => {
    console.log('onConfirm方法未绑定');
  },
};


WxSubscribe.propTypes = {
  isMQQ: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  isWxMini: PropTypes.bool,
  isWxSubscribe: PropTypes.bool,
  wxTemplateId: PropTypes.string,
};


export default WxSubscribe;
