/*
    deprecated-mobile-embed\src\service\saga\saga-util\post
*/
import React, { Component } from 'react';
import classnames from 'classnames';
import Tag from 'components/tag';
import './index.less';
import successImg from './img/send-ok-wx-push.png';
import {
  postApiNotificationUserVerifySend,
  postApiNotificationUserVerifyCode,
  getApiNotificationSubscribeInfo,
} from 'components/api';
import { getProductId } from 'components/util';
import { Modal } from 'components/modal';
import { GlobalToast } from 'components/global-toast';
import PropTypes from 'prop-types';
import { TYPE, SCENE, METHOD, EMAIlSTATUS } from '../../constants';

class EmailSubscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successEmail: '',
      successEmailCode: '',
      cIndex: 1,
      toastType: '',
      toastText: '',
      loading: false,
      yztime: 59,
    };
    this.showToast = this.showToast.bind(this);
  }

  componentDidMount() {
    this.isSubscribed();
  }

  /**
     * 显示全局提示
     * @param text              提示类型，如果是错误，直接传入 Error 对象
     * @param DO_NOT_AUTO_HIDE  是否阻止自动隐藏
     */
  showToast(type, text, DO_NOT_AUTO_HIDE = false) {
    this.setState({ toastType: type, toastText: text });

    if (!DO_NOT_AUTO_HIDE) {
      setTimeout(() => this.hideToast(), 2000);
    }
  }
  /**
     * 隐藏全局提示
     * */
  hideToast() {
    this.setState({ toastType: '' });
  }
  getProductIdFun() {
    return getProductId();
  }

  handleEmailChange = (e) => {
    this.setState({ successEmail: e.target.value });
  };
  handleEmailCodeChange = (e) => {
    this.setState({ successEmailCode: e.target.value });
  }
  modifyEmail = () => {
    this.setState({ cIndex: 2 });
  }

  count = () => {
    let { yztime } = this.state;
    const siv = setInterval(() => {
      this.setState({ yztime: (yztime -= 1) }, () => {
        if (yztime <= -1) {
          clearInterval(siv);
          this.setState({ loading: false, yztime: 59 });
        }
      });
    }, 1000);
  }

  confirmEmail = () => {
    if (!(/^[a-zA-Z0-9_.-]+@+[a-zA-Z0-9]+.+[A-z]/.test(this.state.successEmail))) {
      this.showToast('minLimit', '请输入正确的邮箱地址');
      return Promise.reject(new Error('请输入正确的邮箱地址'));
    }
    this.setState({ loading: true });
    if (!this.state.yztime === 0) this.count();
    postApiNotificationUserVerifySend({
      productId: parseInt(this.getProductIdFun(), 10),
      user_id: this.props.user_id,
      channel: METHOD.email,
      channel_uid: this.state.successEmail,
      type: 'code',
    }).then((res) => {
      console.log(res);
    });
  }

  onCancelFun = () => {
    this.state.cIndex === 1 ? this.props.onCancel() : this.props.onCancel('cancel');
  }

  onConfirmFun = () => {
    const { cIndex, successEmail, successEmailCode } = this.state;
    switch (cIndex) {
    case 1:
      this.setState({ cIndex: 2 });
      break;
    case 2:
      if (cIndex == 2 && (successEmail === '' || successEmailCode === '')) return;
      postApiNotificationUserVerifyCode({
        productId: parseInt(this.getProductIdFun(), 10),
        user_id: this.props.user_id,
        code: this.state.successEmailCode,
        channel: METHOD.email,
        channel_uid: this.state.successEmail,
      }).then((res) => {
        console.log(res);
        this.showToast('success', '邮箱验证成功');
        this.setState({ cIndex: 3 });
        this.props.onConfirm();
      })
        .catch((error) => {
          if (error.message === '无效的验证码') this.showToast('minLimit', error.message);
        });
      break;
    case 3:
      this.props.onCancel();
      break;
    default:
      break;
    }
  }

  isSubscribed = () => {
    getApiNotificationSubscribeInfo(parseInt(this.getProductIdFun(), 10), {
      channel: METHOD.email,
      scene: this.props.scene === TYPE.REPLY ? SCENE.REPLY : SCENE.POST,
    })
      .then((res) => {
        const emailInfo = res.data.email_info.email_info;
        if (emailInfo.status === EMAIlSTATUS.verified) this.setState({ cIndex: 3, successEmail: emailInfo.email });
      });
  }

  render() {
    const { state } = this;
    return (
      <Modal onClose={this.onCancelFun}>
        <Tag.div className={classnames('email', 'email--show')}>
          <Tag.header className="email__header">
            {state.cIndex === 1 && '成功提交'}
            {state.cIndex === 2 && '请填写邮箱信息'}
            {state.cIndex === 3 && '请确认邮箱信息'}
          </Tag.header>
          {state.cIndex === 1 && <Tag.img className="email__success-icon" src={successImg} alt="" />}
          <Tag.article className="email__article">
            <Tag.span>{state.cIndex == 1 ? '马上开通提醒, 有新回复第一时间通知你' : '通过邮箱接收管理员回复推送'}</Tag.span>
          </Tag.article>
          {state.cIndex === 2 && <Tag.div>
            <Tag.input
              type="text"
              className="email__input"
              placeholder={'请填写电子邮箱'}
              value={state.successEmail}
              onChange={this.handleEmailChange}
            />
            <Tag.input
              type="text"
              className="email__input email__input--code"
              placeholder={'输入验证码'}
              value={state.successEmailCode}
              onChange={this.handleEmailCodeChange}
            />
            <Tag.button type="button" className="email__button" onClick={this.confirmEmail}>
              {state.loading ? `${state.yztime}秒` : '发送验证码'}
            </Tag.button>
          </Tag.div>}
          {state.cIndex == 3 && <Tag.div className="email__div">
            <Tag.input
              type="text"
              className="email__input"
              placeholder={'请填写电子邮箱'}
              value={state.successEmail}
            />
            <Tag.span className="email__input--Modify" onClick={this.modifyEmail} >修改</Tag.span>
          </Tag.div>}
          <Tag.footer className="email__footer">
            <Tag.span
              onClick={this.onCancelFun}
              role="button"
              tabIndex={-1}
              className="email__btn email__btn--cancel">
              {state.cIndex === 1 ? '取消' : '跳过'}
            </Tag.span>
            <Tag.span
              onClick={this.onConfirmFun}
              className={`email__btn ${state.cIndex == 2 && (state.successEmail == '' || state.successEmailCode == '') ? 'email__btn--active' : 'email__btn--confirm'}`}
              role="button"
              tabIndex={0}>
              {state.cIndex === 1 && '开通邮箱提醒'}
              {state.cIndex === 2 && '完成验证'}
              {state.cIndex === 3 && '确认订阅'}
            </Tag.span>
          </Tag.footer>
        </Tag.div>
        <GlobalToast type={state.toastType} text={state.toastText} postContent={state.toastText} />
      </Modal>
    );
  }
}
EmailSubscribe.propTypes = {
  user_id: PropTypes.string,
  scene: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};
EmailSubscribe.defaultProps = {
  onCancel: () => {
    console.log('onCancel方法未绑定');
  },
  onConfirm: () => {
    console.log('onConfirm方法未绑定');
  },
};
export default EmailSubscribe;
