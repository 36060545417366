import React, { FC } from 'react';
import '../wx-subscribe/index.less';
import { Modal } from 'components/modal';
import Tag from 'components/tag';
import successImg from '../wx-subscribe/img/send-ok-wx-push.png';
import { ariaRole } from 'components/util/aria';
import './style.less';

interface NoSubscribeProps {
  onClose: () => void;
}

const NoSubscribe:FC<NoSubscribeProps> = ({ onClose }) => (
  <Modal onClose={onClose}>
    <Tag.div className="wx-subscribe wx-subscribe--show">
      <Tag.header className="wx-subscribe__header">成功提交</Tag.header>
      <Tag.img className="wx-subscribe__success-icon" src={successImg} alt="" />
      <Tag.article className="wx-subscribe__article">
        <Tag.span>感谢您的建议和反馈</Tag.span>
      </Tag.article>
      <Tag.footer className="wx-subscribe__footer">
        <Tag.span
          className="wx-subscribe__btn wx-subscribe__btn--confirm"
          {...ariaRole('button')}
          tabIndex={0}
          onClick={onClose}
        >
          确认
        </Tag.span>
      </Tag.footer>
    </Tag.div>
  </Modal>
);

export default NoSubscribe;
