import React, { useRef, useEffect } from 'react';
import { Collect } from 'components/report';
import { METHOD } from 'components/notification/constants';


const STATUS_TYPE = {
    ACCEPT: 'accept',
    REJECT: 'reject',
    CANCEL: 'cancel',
};

function WxOpenSubscribe({ onConfirm, onCancel, className, wxTemplateId, style, children }) {
    const subscribe = useRef(null);
    useEffect(() => {
        const onSuccess = (e) => {
            const { detail: { subscribeDetails }} = e;
            let detail = {};
            try {
                detail = JSON.parse(subscribeDetails);
            } catch {
                console.log('消息订阅结果异常', subscribeDetails);
                return;
            }

            const result  = detail[wxTemplateId];

            if (!result) {
                console.log('消息订阅结果参数异常', subscribeDetails);
                return;
            }
            const resultObj = JSON.parse(result);

            if (resultObj.status === STATUS_TYPE.ACCEPT) {
                onConfirm();
            }
            if (resultObj.status === STATUS_TYPE.CANCEL || resultObj.status === STATUS_TYPE.REJECT) {
                new Collect({ ea: '用户侧-取消订阅弹窗', ev: METHOD.wx_subscribe }).report();
            }
        };
        const onError = (e) => {
            console.log(`e: ${JSON.stringify(e.detail)}`);
        };
        const current = subscribe.current;
        subscribe.current && subscribe.current.addEventListener('success', onSuccess);
        subscribe.current && subscribe.current.addEventListener('error', onError);


        return () => {
            current && current.removeEventListener('success', onSuccess);
            current && current.removeEventListener('error', onError);
        };
    }, []);

    return (
        <wx-open-subscribe
            template={wxTemplateId}
            id="subscribe-btn"
            className={className}
            ref={subscribe}
            // 这里只能用行内样式 否则渲染会异常
            style={style}
        >
            <script type="text/wxtag-template">
                {children}
            </script>
        </wx-open-subscribe>
    );
}

export default WxOpenSubscribe;